<template>
  <div>
    <transition name="fade">
      <div class="name_card" v-if="show_card" > 
        <div class="recommended_name" v-on:click="toggle_timeseries">
          <!--  -->
          {{this.name_properties.name}}
        </div> 
        <div class="number_last_year">Top {{this.name_properties.rank}}{{this.current_country}}
        </div> 
        <div class="toggle_timeseries_icon" v-on:click="toggle_timeseries">
          <span v-show="!show_ts_graph"><font-awesome-icon icon="caret-down" /></span>
          <span v-show="show_ts_graph"><font-awesome-icon icon="caret-up" /></span>
        </div>
        <!-- <div class="remove_button" v-on:click='remove_name'> 
          <font-awesome-icon icon="times" />
        </div>  -->
        <div v-show="show_ts_graph" class="center-align ">
            <time-series-chart v-bind:data='name_properties'  class="timeseries_graph"/>
            <div class='has-text-centered users_also_clicked_on' v-if="this.name_properties.similar_names!=''">
              <p class="info_title">{{$t('recommended_name_card_component.info_title')}}</p>
              <p class="names">{{this.name_properties.similar_names[0]}}, 
                {{this.name_properties.similar_names[1]}}, 
                {{this.name_properties.similar_names[2]}}</p>
            </div>
        </div>
        <!-- <goedgemerkt-modal v-if="show_goedgemerkt_modal"/> -->
      </div> 
    </transition>
  </div>
</template>

<script>
import TimeSeriesChart from './TimeSeriesChart'
import {event_bus} from '../../main' 
export default{
  props:['name_properties'],
  components:{
    'time-series-chart':TimeSeriesChart,
  },
  data(){
    return{
      locale:'',
      show_ts_graph:false,
      show_goedgemerkt_modal:false,
      show_card:true,
    }
  },  
  i18n:{
    messages:{
      be_nl:{
        recommended_name_card_component:{
          info_title: 'Gebruikers klikten ook op:',
        }
      },
      nl:{
        recommended_name_card_component:{
          info_title: 'Gebruikers klikten ook op:',
        }
      },      
      be_fr:{
        recommended_name_card_component:{
          info_title: 'Les utilisateurs sont également intéressés par:',
        }
      },
      fr:{
        recommended_name_card_component:{
          info_title: 'Les utilisateurs sont également intéressés par:',
        }
      },
      de:{
        recommended_name_card_component:{
          info_title: 'Benutzer klickten auch auf:',
        }
      },            

    }
  },
  computed:{
    current_country(){
      // Set current country str
      if(this.locale=='nl'){
        return ' in Nederland'
      }else if(this.locale=='be_nl'){
        return ' in Vlaanderen'
      }else if(this.locale=='be_fr'){
        return 'en Wallonie'
      } 
    }
  },
  created(){
    // Show graph or not, depends on index
    //this.show_ts_graph = true;
    if([0,2,4,6,8,11,15,18,21,26].indexOf(this.name_properties.index) > -1){
      this.show_ts_graph = true;
    }
    // If it is the short list, always show the time series
    if(!this.$parent.full_list){
      this.show_ts_graph = false;
    }
    // Parse list of similar names
    this.name_properties.similar_names = this.name_properties.similar_names = '' ? '' : this.name_properties.similar_names.split(',')
    // Locale issue
    this.locale = this.$i18n.locale;
    event_bus.$on('language_set', function(locale){
      this.locale = locale
    }.bind(this));
  },
  methods:{
    remove_name(){
      this.show_card = false;
      this.$parent.$parent.$parent.register_click('name_removed', 
        {'name': this.name_properties.name})
    },
    toggle_timeseries(){
      // Register with google analytics. How to view this in Google? We only want to see one toggle per user?
      // Registering every toggle is just usefull for the session time, every time there is event google still knows
      // the user is on the page
      this.$ga.event({
        eventAction:'3A_toggling_timeseries_name', 
        eventCategory:'building_model',
      })
      fbq('trackCustom', '3A_toggling_timeseries_name');
      this.show_ts_graph=!this.show_ts_graph;
      this.$parent.$parent.$parent.register_click('time_series_toggled', 
        {'name': this.name_properties.name})
    },
    popup_goedgemerkt_modal(){
      // Google analytics
      this.$ga.event({
        eventAction:'3B_winning_name', 
        eventCategory:'building_model',
      })
      fbq('trackCustom', '3B_winning_name');
      this.$parent.$parent.$parent.register_click('winning_name_clicked', 
        {'winning_name': this.name_properties.name})
      this.show_goedgemerkt_modal=!this.show_goedgemerkt_modal      
    }
  }

}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
@import '../../../public/config.scss';
@import url('https://fonts.googleapis.com/css?family=Josefin+Sans:700|Raleway:300,500,700');

.name_card{
  background: white;
	box-shadow: 1px 1px 8px 0 #bfae11;
	position: relative;
  max-width:400px;
  margin: auto;
  margin-top: 0.75em;
}
.recommended_name {
  padding-left: 1em;
  padding-top: 1em;
  font-weight: bold;
  font-size: 1.5em;
  color: $reddish-orange;
  cursor: pointer;
}
.remove_button{
  font-size: 1.1em;
  position: absolute;
  top:0.5em;
  right:  0.5em;
  color:grey;
}
.toggle_timeseries_icon{
  font-size: 2em;
  position: absolute;
  top:0.5em;
  right:0.5em;
  color:$reddish-orange;
}

.winner_button{
  font-size: 1.1em;
  padding: 0.5em 0.75em;
  position: absolute;
  top:0.5em;
  right:  0.5em;
  color: white;
  background: $dark-sky-blue;
  text-align: center;
  cursor: pointer;
  border-radius: 50%;
  box-shadow: 0 3px 6px 0 $dark-sky-blue;
  
  &:hover {
          background: darken($dark-sky-blue, 20%);
      }
  .fa{
    font-size: 1.7em;
    transform: translateX(5%) translateY(20%);
    -webkit-transform: translateX(5%) translateY(20%);
  }
  
}
.number_last_year {
  font-weight: bold;
  padding-left: 1.5em;
  padding-bottom: 1.5em;
  color: $dark-sky-blue;
}
.users_also_clicked_on{
  padding-top:1em;
  padding-bottom:1em;
}
.users_also_clicked_on .info_title{
  font-weight: bold;
  color: $dark-sky-blue;
}

.timeseries_graph{
  height:200px;
}
.fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>
