<template>
  <div>
		<component v-bind:is='active_component' v-bind='currentProps'></component>
	</div>
</template>

<script>

import axios from 'axios';
import SelectSex from '../components/RecommenderSystemComponents/SelectSex.vue'
import SelectMultipleChoice from '../components/RecommenderSystemComponents/SelectMultipleChoice.vue';
import AwaitingRecommendations from '../components/RecommenderSystemComponents/AwaitingRecommendations.vue';
import Recommendations from '../components/RecommenderSystemComponents/Recommendations.vue';

import {event_bus} from '../main'

export default {
	components:{
		'select-sex': SelectSex, 
		'select-multiple-choice': SelectMultipleChoice,
		'awaiting-recommendations': AwaitingRecommendations,
		'recommendations': Recommendations,
	},
  props:{
  },
  created(){
    this.locale = this.$i18n.locale;
    event_bus.$on('language_set', function(locale){
      this.locale = locale
    }.bind(this));
    // Listen for the home button
    event_bus.$on('start_button_clicked', function(){
			//this.$parent.register_click('home_button_clicked', '')
      this.active_component = 'select-sex'
      this.recommendations = ''
      //this.$router.push({ path: '/' })
    }.bind(this))   
    // If it is a user just checking his recommendations, query his results and render:
    if(this.$route.name == 'view-recommendations'){
      // Get the model id out of the url
      this.$parent.session_parameters.model_id = this.$route.params.model_id
      //  We need new session id
      this.$parent.session_parameters.session_id = this.$parent.make_id()
      axios
      	.get(process.env.VUE_APP_API_URL + 'req_model_results', {params: {
          'model_id': this.$route.params.model_id,
        }})
        .then(function(response){
					this.recommendations = response['data']
          this.active_component = 'recommendations'
          // When a user id requests previous results, the saved user_id, sex and locale is stored and returned. 
          //this.$parent.session_parameters.user_id = response['data']['user_id']
          this.selected_data.sex = response['data']['selected_sex']
          event_bus.$emit('language_set', response['data']['locale'])
          // Now that we now the user_id, register the session
          // axios
          //   .get(this.$parent.base_url + '/register_session', {
          //   params: {
          //   'user_id': this.$parent.session_parameters.user_id,
          //   'session_id': this.$parent.session_parameters.session_id,
          //   'referrer': document.referrer,
          //   'unstructured': {'source':'user_rechecking_results_via_email', 
          //                     'model_id':this.$parent.session_parameters.model_id},
          // }})
        }.bind(this))
      // Take the spinner of and hide language buttons
			this.$parent.spinner_active = false;
			// Fire an event to force to show the home button
			event_bus.$emit('force_home_button_to_show')
    }
  },
	data(){
		return {
      locale:'',
			active_component: 'select-sex',
			selected_data:{
				sex:'',
				multiple_choice_answers:'',
			},
			names_list_multiple_choice:'',
			recommendations:'',
		}
	},
	computed: {
		currentProps: function(){
			if(this.active_component == 'recommendations'){
				return {recommendations: this.recommendations}
			}
		}
	},
	methods:{
    register_scroll(){
    },
		move_to_multiple_choice(){
			// Activate spinner as we are making a backend call
			this.$parent.spinner_active = true;  
			// Get names in backend
			axios
      	.get(process.env.VUE_APP_API_URL + 'req_multiple_choice_names', {params: {
          'selected_sex': this.selected_data.sex, 
          'locale': this.locale,
          'user_id': this.$parent.session_parameters.user_id,
          'session_id': this.$parent.session_parameters.session_id,
          'model_id': this.$parent.session_parameters.model_id,
              }})
      	.then(function(response){
          // Register on Google analytics
          this.$ga.event({
            eventAction:'1B_multiple_choice_answers_received', 
            eventCategory:'building_model',
          })
          fbq('trackCustom', '1B_multiple_choice_answers_received');
          this.$parent.register_click('multiple_choice_names_recieved', '')
          this.names_list_multiple_choice= response['data'] 
          this.active_component = 'select-multiple-choice'
          this.$parent.spinner_active = false;  
				}.bind(this))
				.catch(function (error) {
					this.$parent.register_click('multiple_choice_not_received', {'error': error})
				}.bind(this));
		},
		handle_multiple_choice_answers(){
			this.$parent.register_click('last_question_clicked', '')
			this.active_component='awaiting-recommendations';
			// Send the answer to backend
			axios({
					method: 'post',
					url: process.env.VUE_APP_SERVERLESS_URL + 'get_recommendations',
					data: {
						selected_sex: this.selected_data.sex,
						selected_locale: this.locale,
						feedback_json : this.selected_data.multiple_choice_answers,
						user_id: this.$parent.session_parameters.user_id,
						session_id: this.$parent.session_parameters.session_id,
						model_id: this.$parent.session_parameters.model_id
					},
					config: { headers: {'Content-Type': 'multipart/form-data' }}, 
					timeout: 12000,
					})
					.then(function(response){
						this.recommendations = response['data']
						this.$parent.register_click('recommendations_received', '')
						this.active_component = 'recommendations'
						// Register with GA that the suggestions are returned
						this.$ga.event({
						eventAction:'2B_suggestions_received', 
						eventCategory:'building_model',
						})
						// Show the navbar again
						this.$parent.navbar.show=true;
					}.bind(this))
					.catch(function (error) {
						// Register error in SQL
						this.$parent.register_click('recommendations_not_received', {'error': error})
						// Try to catch the recommendations from Flask API and SQL
						axios.get(
							process.env.VUE_APP_API_URL + 'req_model_results',
							 {params: { 'model_id': this.$parent.session_parameters.model_id}
							})
							.then(function(response){
								// If this was successful, show recommendations
								this.recommendations = response['data']
								this.$parent.register_click('recommendations_received', '')
								this.active_component = 'recommendations'
								// Register with GA that the suggestions are returned
								this.$ga.event({
									eventAction:'2B_suggestions_received', 
									eventCategory:'building_model',
								}) 
								}.bind(this));
						// Show the navbar again
						this.$parent.navbar.show=true;
					}.bind(this))
		}
	}
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
