<template>
  <div>
    <section class="section">
      <h1 class='title'>Build me</h1>
      <p>Lorem ipsum is a pseudo-Latin text used in web design, typography, layout, and printing in 
        place of English to emphasise design elements over content. 
        It's also called placeholder (or filler) text. It's a convenient tool for mock-ups. 
        It helps to outline the visual elements of a document or presentation, eg typography, 
        font, or layout. Lorem ipsum is mostly a part of a Latin text by the classical author and 
        philosopher Cicero. Its words and letters have been changed by addition or removal, 
        so to deliberately render its content nonsensical; it's not genuine, correct,</p> 
    </section>
  </div>
</template>

<script>
export default {
  metaInfo() {
    return{
      // if no subcomponents specify a metaInfo.title, this title will be used
      // title: this.$t('app_component.title'),
      title:'Babynamen op maat | Names I Like',
      // all titles will be injected into this template
      titleTemplate: '%s', 
      meta: [
        { charset: 'utf-8' },
        { name: "viewport", content: "width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no" },
        { name: 'google', content: 'notranslate' },
        { name: 'google-site-verification', content: 'onWYxFnSJqLMzGgtpht5CWBMGoRJQmx9dbt1xjwFGL8' },
        // { name: 'lang', content: this.$t('app_component.language_meta') },
        { name: 'lang', content: 'nl' },
        { name: 'description', content: 'Met ons algoritme was naar babynamen zoeken nog nooit zo leuk! We verzamelen alle babynaam stastieken en geven persoonlijke suggesties!' },                             
        // { name: 'title', content: this.$t('app_component.title') },
        { name: 'title', content: 'Babynamen op maat | Names I Like' },
        // { property: 'og:title', content: this.$t('app_component.title') },   
        { property: 'og:title', content: 'Babynamen op maat | Names I Like' },  
        // { property: 'og:description', content: this.$t('app_component.description') },   
        { property: 'og:description', content: 'Met ons algoritme was naar babynamen zoeken nog nooit zo leuk! We verzamelen alle babynaam stastieken en geven persoonlijke suggesties!' },   
        { property: 'og:url', content: 'https://www.namesilike.com' },   
        { property: 'og:locale', content: 'nl' },   
        ],
    }
  }, 
	components:{
	},
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
