// Ready translated locale messages
export default {
    be_nl: { 
      popup_modal_winning_name:{
        title:'Schitterende keuze!',
        p1:'Al eens gehoord van <b>Goedgemerkt.nl?</b>',
        p2:'Bestel snel hun handige naam stickers met <b>10% korting</b>, en verlies nooit meer je spullen in de creche,',
      }
    },
    nl: { 
      popup_modal_winning_name:{
        title:'Schitterende keuze!',
        p1:'Al eens gehoord van <b>Goedgemerkt.nl?</b>',
        p2:'Bestel snel hun handige naam stickers met <b>10% korting</b>, en verlies nooit meer je spullen in de creche.',
      }
    },
    be_fr:{
      popup_modal_winning_name:{
        title:'Bien choisi!',
        p1:'J\'ai déjà entendu parler <b>Goedgemerkt.fr?</b>',
        p2:'Commandez rapidement leurs autocollants avec <b>10% de reduction</b>, et ne perdez plus jamais vos affaires dans la creche',
      }      
    },
    de:{
    },
    pl:{
    },
    fr:{
    },
}