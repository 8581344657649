<template>
  <div>
    <section class='section has-text-centered top_section'>
      <h1 class='title' v-html='title'></h1>
      <p class='subtitle' v-html='subtitle'></p>
    </section>
    <!-- <section class='section has-text-centered'>    
      <div class="container sex_button_row">
        <p class='info_line'>1. Zoek je een jongens- of meisjesnaam?</p>
        <a class="button select_sex_button is-rounded" v-on:click="switch_sex('m')" 
           v-bind:class="{ 'is-info':  (naar == 'm'), 'not_clicked': (naar != 'm')}">
          <span class="sex_icon"><font-awesome-icon icon="mars"/></span> JONGENSNAAM
        </a>
        <a class="button select_sex_button is-rounded" v-on:click="switch_sex('f')" 
          v-bind:class="{ 'is-info':  (naar == 'f'), 'not_clicked': (naar != 'f') }">
          <span class="sex_icon"><font-awesome-icon icon="venus"/></span> MEISJESNAAM
        </a>    
      </div> 
    </section> -->
    <section class='section has-text-centered name_buttons_section'>
      <div class="container name_buttons_row">
        <h2 class='subtitle'>Klik op een naam dewelke je mooi vindt om de lijst te sorteren.</h2>
        <ul id="example-1">
          <li v-for="item in Object.keys(current_popularities)" :key="item">
            <a class="button is-rounded" v-bind:class="{ 'is-info':  selected_name == item, 
                                                          'not_clicked': selected_name != item }"
             v-on:click="select_other_name(item)">{{ item }}</a>
          </li>
        </ul>
      </div>
    </section>
    <section class='section has-text-centered graph_section'>
      <div class="container" id="graph_container">
        <!-- <h2 class='subtitle'>100 populairste meisjesnamen in Vlaanderen:</h2> -->
          <svg id="graph_svg"></svg>
      </div>
    </section>
  </div>

</template>
<script>
import * as d3 from 'd3';

import popularities_m_vlaanderen from './../assets/inspiration_tool_data/popularities_m_vlaanderen.json'
import popularities_f_vlaanderen from './../assets/inspiration_tool_data/popularities_f_vlaanderen.json'

import popularities_looking_for_F from './../assets/inspiration_tool_data/popularities_looking_for_F.json'
import popularities_looking_for_M from './../assets/inspiration_tool_data/popularities_looking_for_M.json'
import {event_bus} from '../main' 

export default {
  metaInfo() {
    return{
      title: this.$route.name == 'meisjesnamen' ? 'Inspiratie nodig voor een meisjesnaam?' : 'Inspiratie nodig voor een jongensnaam?',
      meta: [
          { charset: 'utf-8' },
          { name: "viewport", content: "width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no" },
          { name: 'google', content: 'notranslate' },
          { name: 'google-site-verification', content: 'onWYxFnSJqLMzGgtpht5CWBMGoRJQmx9dbt1xjwFGL8' },
          { name: 'description', content: 'Zoek je een leuke meisjesnaam of jongensnaam? Met slechts twee klikken zetten we je alvast op weg!' },                             
          { name: 'title', content: this.$route.name == 'meisjesnamen' ? 'Inspiratie nodig voor een meisjesnaam?' : 'Inspiratie nodig voor een jongensnaam?', },   
          { property: 'og:title', content: this.$route.name == 'meisjesnamen' ? 'Inspiratie nodig voor een meisjesnaam?' : 'Inspiratie nodig voor een jongensnaam?', },   
          { property: 'og:description', content: 'Zoek je een leuke meisjesnaam of jongensnaam? Met slechts twee klikken zetten we je alvast op weg!' },   
          { property: 'og:url', content: 'https://www.namesilike.com' },   
          { property: 'og:locale', content: 'nl' },  
          { property: 'og:image', content:'https://www.namesilike.com/stock_photo.jpg'}
          ],
      // noscript: [  { innerHTML: ' <img height="1" width="1" src="https://www.facebook.com/tr?id=497443107438912&ev=PageView&noscript=1"/>' },
      //                 { innerHTML: ' <img height="1" width="1" src="https://www.facebook.com/tr?id=747412312303426&ev=PageView&noscript=1"/>' }         
      // ],  
      // script: [
      //     { body: true, type: 'text/javascript', innerHTML: "!function(f,b,e,v,n,t,s){if(f.fbq)return;n=f.fbq=function(){n.callMethod?n.callMethod.apply(n,arguments):n.queue.push(arguments)};if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0'; n.queue=[];t=b.createElement(e);t.async=!0; t.src=v;s=b.getElementsByTagName(e)[0]; s.parentNode.insertBefore(t,s)}(window,document,'script', 'https://connect.facebook.net/en_US/fbevents.js');fbq('init', '497443107438912'); fbq('init', '747412312303426');  fbq('track', 'PageView');"  }
      // ],      
      // htmlAttrs: {
      //   lang: 'nl',
      //   amp: true
      // }
    }
  },

  // metaInfo() {
  //   return{
  //     // if no subcomponents specify a metaInfo.title, this title will be used
  //     title: this.$t('app_component.title'),
  //     // all titles will be injected into this template
  //     titleTemplate: '%s', 
  //     meta: [
  //       { charset: 'utf-8' },
  //       { name: "viewport", content: "width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no" },
  //       { name: 'google', content: 'notranslate' },
  //       { name: 'google-site-verification', content: 'onWYxFnSJqLMzGgtpht5CWBMGoRJQmx9dbt1xjwFGL8' },
  //       { name: 'lang', content: this.$t('app_component.language_meta') },
  //       { name: 'description', content: this.$t('app_component.description') },                             
  //       { name: 'title', content: this.$t('app_component.title') },   
  //       { property: 'og:title', content: this.$t('app_component.title') },   
  //       { property: 'og:description', content: this.$t('app_component.description') },   
  //       { property: 'og:url', content: 'https://www.namesilike.com' },   
  //       { property: 'og:locale', content: 'nl' },   
  //       ],
  //     noscript: [  { innerHTML: ' <img height="1" width="1" src="https://www.facebook.com/tr?id=497443107438912&ev=PageView&noscript=1"/>' },
  //                     { innerHTML: ' <img height="1" width="1" src="https://www.facebook.com/tr?id=747412312303426&ev=PageView&noscript=1"/>' }         
  //     ],  
  //     script: [
  //         { body: true, type: 'text/javascript', innerHTML: "!function(f,b,e,v,n,t,s){if(f.fbq)return;n=f.fbq=function(){n.callMethod?n.callMethod.apply(n,arguments):n.queue.push(arguments)};if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0'; n.queue=[];t=b.createElement(e);t.async=!0; t.src=v;s=b.getElementsByTagName(e)[0]; s.parentNode.insertBefore(t,s)}(window,document,'script', 'https://connect.facebook.net/en_US/fbevents.js');fbq('init', '497443107438912'); fbq('init', '747412312303426');  fbq('track', 'PageView');"  }
  //     ],
  //   }
  // },

  data: function(){
    return {
      chartOptions:{
        responsive: true,
        maintainAspectRatio: false
      },
      selected_name: 'Matteo',
      naar:'f',
      already_scrolled:false,
      // popularities_f_to_m: popularities_f_to_m,
      // popularities_f_to_f: popularities_f_to_f,
      // popularities_m_to_f: popularities_m_to_f,
      // popularities_m_to_m: popularities_m_to_m,
      popularities_m_vlaanderen:popularities_m_vlaanderen,
      popularities_f_vlaanderen:popularities_f_vlaanderen,
      popularities_looking_for_F: popularities_looking_for_F,
      popularities_looking_for_M: popularities_looking_for_M,
      current_popularities: popularities_looking_for_F,
      title:'',
      subtitle:'',
    }
  },
  computed:{
    chartData: function(){
      //var temp_labels = temp_array.map(x => x[0])
      //var temp_values = temp_array.map(x => x[1])
      return temp_data
    },
  },  
  components:{
  },
  created:function(){
  },
  mounted: function(){
    if(this.$route.name == 'meisjesnamen'){
      this.naar='f';
      this.current_popularities=popularities_looking_for_F;
      this.title = 'Leuke meisjesnamen';
      this.subtitle='Onderaan de pagina vind je een lijst met de in <b>Vlaanderen 75 populairste meisjesnamen.</b><br><br>'
    }else if(this.$route.name == 'jongensnamen'){
      this.naar='m';
      this.current_popularities=popularities_looking_for_M;
      this.title = 'Leuke jongensnamen';
      this.subtitle='Onderaan de pagina vind je een lijst met de in <b>Vlaanderen 75 populairste jongensnamen.</b><br><br>'      
    }
    setTimeout(function(){ this.redraw_chart(); }.bind(this), 1000);
    event_bus.$emit('force_language_buttons_to_hide');
    event_bus.$emit('force_home_button_to_show');
  },
  methods:{
    switch_sex: function(looking_for_sex){
      this.naar = looking_for_sex
      this.current_popularities = this.naar=='f' ? popularities_looking_for_F : popularities_looking_for_M;
      this.redraw_chart();
      var VueScrollTo = require('vue-scrollto');
      var cancelScroll = VueScrollTo.scrollTo('.name_buttons_row')
    },
    select_other_name:function(selected_name){
      this.selected_name = selected_name;
      // Register click
      this.$parent.register_click('inspiration_tool_name_clicked', selected_name)
      // var temp_position = this.selected_names.indexOf(selected_name)
      // if(temp_position > -1){
      //   if(this.selected_names.length>1) this.selected_names.splice(temp_position, 1);
      // }else{
      //   this.selected_names.push(selected_name)
      // }
      this.redraw_chart()
      // Only scroll the first time
      if(!this.already_scrolled){
        var VueScrollTo = require('vue-scrollto');
        var cancelScroll = VueScrollTo.scrollTo('#graph_section')
        this.already_scrolled=true
      }
    },
    redraw_chart: function(){
      const popularities_graph_svg = d3.select('#graph_svg')

      // Wrangle the data into two sorted list: the list of names and the list of popularities. You can't sort objects so make temp array      
      // Loop over all the selected names
      function jsonCopy(src) { // Help function to deep copy object
          return JSON.parse(JSON.stringify(src));
      }
      var temp_total_popularities = jsonCopy(this.current_popularities[this.selected_name])
      if((this.naar=='f')){
        var general_popularities = this.popularities_f_vlaanderen
      }
      else if((this.naar=='m')){
        var general_popularities = this.popularities_m_vlaanderen
      }
      var temp_data = Object.entries(temp_total_popularities).map(
        ([key, value]) => [key, value]);
      temp_data = temp_data.sort(function(a,b){return  b[1] - a[1] })

      // Add the general popularity in flanders

      for (var i = 0; i < temp_data.length; i++) {
        var temp_name = temp_data[i][0]
        temp_data[i].push(general_popularities[temp_name]['smooth_2016'])
      }

      // Caclulcate max of both names i like popularity and general popularity in Flanders for d3scales
      var max_reducer = (accumulator, currentValue) =>  currentValue[1] > accumulator?currentValue[1]:accumulator;
      var max_popularity_nil = temp_data.reduce(max_reducer, 0)
      max_reducer = (accumulator, currentValue) =>  currentValue[2] > accumulator?currentValue[2]:accumulator;
      var max_popularity_flanders = temp_data.reduce(max_reducer, 0)
      
      var min_reducer = (accumulator, currentValue) =>  currentValue[1] < accumulator?currentValue[1]:accumulator;
      var min_popularity_nil = temp_data.reduce(min_reducer, 9999)
      min_reducer = (accumulator, currentValue) =>  currentValue[2] < accumulator?currentValue[2]:accumulator;
      var min_popularity_flanders = temp_data.reduce(min_reducer, 9999)

      // D3 Stuff
      var svg_height = document.getElementById('graph_container').offsetHeight;
      var svg_width = document.getElementById('graph_container').offsetWidth;
      svg_width = svg_width > 400 ? 400 : svg_width
      var margin_top = 40;
      var margin_bottom = 10;
      var margin_right = 0;
      var margin_left = 0;
      var space_for_names = 100;
      var margin_bar = 8
      var bar_height = (svg_height - margin_top - margin_bottom)/temp_data.length - margin_bar

      function return_key(d){ return d[0] }

	    var width_scale_nil_right = d3.scaleLinear()
	 					.domain([min_popularity_nil,max_popularity_nil])
	 					.range([0, svg_width/2 - margin_right - space_for_names/2]);

	    var width_scale_flanders_left = d3.scaleLinear()
	 					.domain([min_popularity_flanders,max_popularity_flanders])
             .range([0, svg_width/2 - margin_left - space_for_names/2]);

      const rectangles_right_selection = popularities_graph_svg.selectAll('rect').filter(".rect_right")
            .data(temp_data, return_key)

      const rectangles_left_selection = popularities_graph_svg.selectAll('rect').filter(".rect_left")
            .data(temp_data, return_key)

      // Grey background bar
      rectangles_right_selection.enter()
            .append('rect')
            .attr('class', 'rect_right_background')
						.attr('x', function(d){
							return svg_width / 2 + space_for_names / 2
						})
						.attr('y', function(d, i){
							return margin_top + (bar_height + margin_bar)* i
						})
						.attr('height', function(d,i){
							return bar_height
						})
						.attr('width', function(d){
              return width_scale_nil_right(max_popularity_nil)
            })	
						.attr('fill', function(d){
							return 'rgba(74,144,226,0.1)';
            }) 
            .attr('stroke-width', 1)
            .attr('stroke', 'rgba(74,144,226,0.2)') 
      
      // Real data
      rectangles_right_selection.enter()
            .append('rect')
            .attr('class', 'rect_right')
						.attr('x', function(d){
							return svg_width / 2 + space_for_names / 2
						})
						.attr('y', function(d, i){
							return margin_top + (bar_height + margin_bar)* i
						})
						.attr('height', function(d,i){
							return bar_height
						})
						.attr('width', function(d){
              return width_scale_nil_right(d[1])
            })	
						.attr('fill', function(d){
							return 'rgba(74,144,226,' +(d[1]*2/max_popularity_nil)+')';
            }) 
            .attr('stroke-width', 1)
            .attr('stroke', 'rgb(0,0,0)')           
      
      // Background bars
      rectangles_left_selection.enter()
            .append('rect')
            .attr('class', 'rect_left_background')
						.attr('x', function(d){
              // Hier moet je eens goed over peinzen met pen en papier, zelfde met scale
							return margin_left
						})
						.attr('y', function(d, i){
							return margin_top + (bar_height + margin_bar)* i
						})
						.attr('height', function(d,i){
							return bar_height
						})
						.attr('width', function(d){
              return width_scale_flanders_left(max_popularity_flanders)
            })	
						.attr('fill', function(d){
              return 'rgba(76,175,80,0.1)';
            }) 
            .attr('stroke-width', 1)
            .attr('stroke', 'rgba(76,175,80,0.2)');

      // Real data bars
      rectangles_left_selection.enter()
            .append('rect')
            .attr('class', 'rect_left')
						.attr('x', function(d){
              // Hier moet je eens goed over peinzen met pen en papier, zelfde met scale
							return svg_width / 2 - space_for_names / 2 - width_scale_flanders_left(d[2])
						})
						.attr('y', function(d, i){
							return margin_top + (bar_height + margin_bar)* i
						})
						.attr('height', function(d,i){
							return bar_height
						})
						.attr('width', function(d){
              return width_scale_flanders_left(d[2])
            })	
						.attr('fill', function(d){
              return 'rgba(76,175,80,' +(d[2]*2/max_popularity_flanders)+')';
            }) 
            .attr('stroke-width', 1)
            .attr('stroke', 'rgb(0,0,0)')
      
      rectangles_right_selection.transition()
						.duration(1000)
						.delay(function(d,i){
							return i / temp_data.length * 1000
						})
						.attr('y', function(d, i){
							return margin_top + (bar_height + margin_bar)* i
						})
						.attr('width', function(d){
							return width_scale_nil_right(d[1])
						})	
						.attr('fill', function(d){
							return 'rgba(74,144,226,' +(d[1]*2/max_popularity_nil)+')';
            })		

      rectangles_left_selection.transition()
						.duration(1000)
						.delay(function(d,i){
							return i / temp_data.length * 1000
						})
						.attr('y', function(d, i){
							return margin_top + (bar_height + margin_bar)* i
						})            

	    rectangles_right_selection.exit()
            .remove()    

	    rectangles_left_selection.exit()
            .remove()                
            
      const labels_selection = popularities_graph_svg.selectAll('text').filter('.names')
                          .data(temp_data, return_key)

      labels_selection.enter()
              .append('text')
              .attr('class', 'names')
              .style("font-size", "0.9em")
              .style("font-weight", "500")
              .style("color", "#211551")
              .text(function(d){
                return d[0]
              })
              .attr('x', function(d){
                return svg_width / 2
              })
              .attr('y', function(d,i){
                return margin_top + (bar_height + margin_bar) * i + 10
              })
              .attr('text-anchor', function(d){
                return 'middle'
              })

	    labels_selection.transition()
						.duration(1000)
						.delay(function(d,i){
							return i / temp_data.length * 1000
						})
						.attr('y', function(d,i){
							return margin_top + (bar_height + margin_bar) * i + 10
            })
      
      labels_selection.exit()
            .remove()               

      // Title left
      popularities_graph_svg.selectAll("text.titles").remove() 
      popularities_graph_svg.append("text")
          .attr('text-anchor', "end")
          .attr('class', 'titles')
          .style("font-size", "0.8em")
          .style("color", "#211551")
          .attr("y", 1)
          .attr("x", svg_width/2 - space_for_names/2)
          .attr("dy", "0.9em")
          .text("Populariteit in");  
          
      popularities_graph_svg.append("text")
          .attr('text-anchor', "end")
          .attr('class', 'titles')
          .style("font-size", "0.8em")
          .style("color", "#211551")          
          .attr("y", 1 + 13)
          .attr("x", svg_width/2 - space_for_names/2)
          .attr("dy", "0.9em")
          .text("Vlaanderen");            
      
      // Title right
      popularities_graph_svg.append("text")
          .attr('text-anchor', "left")
          .attr('class', 'titles')
          .style("font-size", "0.8em")
          .style("color", "#211551")          
          .attr("y", 1)
          .attr("x", svg_width/2 + space_for_names/2)
          .attr("dy", "0.9em")
          .text("Passend bij");  
      popularities_graph_svg.append("text")
          .attr('text-anchor', "left")
          .attr('class', 'titles')   
          .style("font-size", "0.8em")
          .style("color", "#211551")                 
          .attr("y", 1 + 13)
          .attr("x", svg_width/2 + space_for_names/2)
          .attr("dy", "0.9em")
          .text(this.selected_name);   
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
@import '../../public/config.scss';
@import url('https://fonts.googleapis.com/css?family=Josefin+Sans:700|Raleway:300,500,700');

.top_section{
  padding-top:4rem;
  padding-bottom:0em;
}

/* select sex buttons */
.sex_button_row{
  padding-bottom:2em;
}
.select_sex_button{
  margin: 0.3em;
  padding: 1.3em 1em;
  padding-left: 0.5em;
  letter-spacing: 0.05em;
  font-size: 0.8em;
  background-color: white;
  color: $dark-indigo;
  border-color:$dark-indigo;
  border-width: 0.5px;
}
.select_sex_button.not_clicked{
  background-color:rgba(255,255,255,0);
  border-color:#363636;  
}
.select_sex_button.is-info{
  background-color: $reddish-orange !important;
}
.sex_icon{
  margin: 0px 0.5em;
}
.info_line{
  font-weight:500;
}
.button_row .button{
  width:7em;
}
.button_container{
  max-width:6em;
  display:inline-block;
}

/* Name button row */
.name_buttons_section{
  padding-top:1em;
}
.name_buttons_row{
  max-width:20em;
}
.name_buttons_row li{
  display: inline-block;
}
.name_buttons_row a{
  margin: 0.2em;
  padding: 0em 0.4em;
  font-size: 0.9em;
  height: 1.8em;
  color: $dark-indigo;
  font-weight: 300;
  border-width: 0.5px;
}
.name_buttons_row a.not_clicked{
  background-color:rgba(255,255,255,0);
  border-color:#363636;
}
.name_buttons_row a.is-info{
  background-color: $reddish-orange !important;
  font-weight:700 !important;

}
.name_buttons_section{
  padding-bottom:0.5em;
}
// Graph
.graph_section{
  padding-top:0em;
  // padding:0em 1em;
}
#graph_container{
  height:1500px;
  margin:auto;
}
#graph_svg{
  margin-top:1em;
  height:100%;
  max-width:400px;
  width:100%
}
</style>
