<template>
  <section class="section">
    <div class='container'>
      <div class='has-text-centered'>
        <h1 class="title">{{$t('select_sex_component.title')}}</h1>
        <h2 class="subtitle">{{$t('select_sex_component.subtitle')}}</h2>
        <div class="columns" id="choose_sex_buttons">
          <div class="column left">
            <a class="button boy" v-on:click="handle_selection('m')">{{$t('select_sex_component.jongen')}}</a>
          </div>  
          <div class="column right" >
            <a class="button girl" v-on:click="handle_selection('f')">{{$t('select_sex_component.meisje')}}</a>
          </div>        
        </div>          
      </div>
    </div>
  </section>
</template>

<script>
import {event_bus} from '../../main' 
export default {
  created(){
    this.locale = this.$i18n.locale;
    event_bus.$on('language_set', function(locale){
      this.locale = locale
    }.bind(this));
  },
  data() {
    return {
      locale:'',
    };
  },
  i18n:{
    messages:{
      be_nl:{
        select_sex_component:{
          title: 'Kies geslacht',
          subtitle:'Ben je op zoek naar een jongen- of meisjesnaam?',
          jongen: 'JONGENSNAAM',
          meisje: 'MEISJESNAAM',
        }
      },
      nl:{
        select_sex_component:{
          title: 'Kies geslacht',
          subtitle:'Ben je op zoek naar een jongen- of meisjesnaam?',
          jongen: 'JONGENSNAAM',
          meisje: 'MEISJESNAAM',
        }
      },      
      be_fr:{
        select_sex_component:{
          title: 'Choisissez le sexe:',
          subtitle:'Vous cherchez un Prénom de garçon ou de fille?',
          jongen: 'Prénom de garçon',
          meisje: 'Prénom de fille',
        }
      },
      fr:{
        select_sex_component:{
          title: 'Choisissez le sexe:',
          subtitle:'Vous cherchez un Prénom de garçon ou de fille?',
          jongen: 'Prénom de garçon',
          meisje: 'Prénom de fille',
        }
      },
      de:{
        select_sex_component:{
          title: 'Wähle das Geschlecht',
          subtitle:'Suchen Sie einen Jungen- oder Mädchennamen?',
          jongen: 'JUNGENNAME',
          meisje: 'MÄDCHENNAME',
        },
      }      
    }
  },
  methods:{
    handle_selection(selected_sex){
      // Select sex
      this.$ga.event({
        eventAction:'1A_sex_selected', 
        eventCategory:'building_model',
      })
      fbq('trackCustom', '1A_sex_selected');
      // Register click
      this.$parent.$parent.register_click('selected_sex', {'sex':selected_sex})
      this.$parent.selected_data.sex = selected_sex
      this.$parent.move_to_multiple_choice();
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import '../../../public/config.scss';
@import url('https://fonts.googleapis.com/css?family=Josefin+Sans:700|Raleway:300,500,700');

#choose_sex_buttons{
  padding-top: 8vw;
}

#choose_sex_buttons .column{
  padding:0em;
  box-shadow: 1px 1px 8px 0 #bfae11;
  max-width:400px;
  margin: auto;
}
.button {
  padding-top: 12em;
  padding-bottom: 1.5em;
  height: auto !important;
  width: 100%;
  
  border:none;
  border-radius:0;
  @media (max-width: 400px) {
      padding-top: 6em;
  }
}
.column.left{
  @media (min-width: 769px) {
    margin-right:0px !important; 
  }
}
.column.right{
  @media (min-width: 769px) {
    margin-left:0px !important; 
  }
}
.button.boy{
  background-image: url('/boy.svg');
  background-position: center 35%;
  background-repeat: no-repeat;
  background-size: auto 30%;
  background-color: $reddish-orange !important;
  box-shadow: none;
  color:white;
}
.button.girl{
  background-image: url('/girl.svg');
  background-position: center 35%;
  background-repeat: no-repeat;
  background-size: auto 30%;
  color: $dark-sky-blue;
  box-shadow: none;
}


/* padding-top: 8vw;

.sex-buttons {
    box-shadow: 1px 1px 8px 0 #bfae11;
}

.sex-buttons .col {
    padding: 0;
}

.sex-buttons a {

    padding-top: 12em;
    padding-bottom: 1.5em;
    height: auto !important;
    width: 100%;

    @media (max-width: 400px) {
        padding-top: 6em;
    }

    &.boy {
        background-image: url('../images/boy.svg');
        background-position: center 35%;
        background-repeat: no-repeat;
        background-size: auto 30%;
        background-color: $reddish-orange !important;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        box-shadow: none;

    }
    &.girl {
        background-image: url('../images/girl.svg');
        background-position: center 35%;
        background-repeat: no-repeat;
        background-size: auto 30%;
        color: $dark-sky-blue;
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
        box-shadow: none;
    }
} */



</style>
