<template>
  <transition name="modal ">
    <div class="modal-mask">
      <div class="modal-wrapper">
        <div class="modal-container">
          <div class="modal-header has-text-left">
            <div class="close_button" v-on:click="close_modal">
              <font-awesome-icon icon="times" class='times' />
            </div>
            <p class="title_nieuwsbrief" v-html="$t('newsletter_modal_component.title')"></p>
            <p v-html="$t('newsletter_modal_component.p1')"></p>
          </div>
          <div class="modal-body has-text-left">
            <p class="has-text-left label_bold">{{$t("newsletter_modal_component.email_adres")}}</p>
            <div class="field">
              <p class="control has-icons-left has-icons-right">
                <input class="input" type="email" placeholder="Email" v-model="email">
                <span class="icon is-small is-left">
                  <font-awesome-icon icon="envelope" />
                </span>
                <span class="icon is-small is-right green_icon" v-if="email_correct">
                  <font-awesome-icon icon="check" />
                </span>
              </p>
            </div>
            <p v-html="$t('newsletter_modal_component.p2')"></p>
            <p class="submit_button" v-bind:class="{disabled:disable_submit_button}" v-on:click="submit_newsletter" 
            v-if="!newsletter_submitted">
              <font-awesome-icon icon="paper-plane" />  {{$t("newsletter_modal_component.send_action")}}
            </p>
            <p class="has-text-centered submitted" v-if="newsletter_submitted">
              {{$t("newsletter_modal_component.subscribed")}}
            </p>

          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import {event_bus} from '../../main' 
export default{
  i18n:{
    messages:{
      be_nl:{
        newsletter_modal_component:{
          title: 'Onze nieuwsbrief',
          p1: 'Met de data van Names I Like zijn we als eerste op de hoogte van de <b>allerlaatste trends</b>. \
          We houden je graag op de hoogte!',
          p2: 'Je email adres wordt enkel voor deze nieuwsbrief gebruikt.',
          send_action:'Schrijf me in',
          email_adres: 'Email adres',
          subscribed:'Ingeschreven!',
        }
      },
      be_fr:{
        newsletter_modal_component:{
          title: 'Notre newsletter',
          p1: 'Avec les données de Names I Like, nous sommes les premiers à connaître les <b> dernières tendances </ b>. \
          Nous vous tiendrons au courant!',
          p2: 'Votre adresse email ne sera utilisée que pour cette newsletter.',
          send_action:'Inscrivez-moi',
          email_adres: 'Adresse email',
          subscribed:'Enregistré!',
        }
      },
      nl:{
        newsletter_modal_component:{
          title: 'Onze nieuwsbrief',
          p1: 'Met de data van Names I Like zijn we als eerste op de hoogte van de <b>allerlaatste trends</b>. \
          We houden je graag op de hoogte!',
          p2: 'Je email adres wordt enkel voor deze nieuwsbrief gebruikt.',
          send_action:'Schrijf me in',
          email_adres: 'Email adres',
          subscribed:'Ingeschreven!',
        }
      },
      de:{
        newsletter_modal_component:{
          title: 'Unser Newsletter',
          p1: 'Mit den Daten von Names I Like sind wir die ersten, die über die neuesten Trends <b> </ b> erfahren. \
          Wir werden Sie auf dem Laufenden halten!',
          p2: 'Ihre E-Mail-Adresse wird nur für diesen Newsletter verwendet.',
          send_action:'Registrieren Sie mich',
          email_adres: 'E-Mail-Adresse',
          subscribed:'Registriert!',
        }
      }      

    }
  },
  props:[],
  components:{
  },
  data(){
    return{
      locale:'',
      newsletter_submitted:false,
      email:'',
    }
  }, 
  created(){
    console.log('Created')
    this.locale = this.$i18n.locale;
    event_bus.$on('language_set', function(locale){
      this.locale = locale
    }.bind(this));
  },
  methods:{
    submit_newsletter(){
      this.$parent.$parent.$parent.register_click('submitted_newsletter',{'email': this.email});
      this.newsletter_submitted = true;
      // Close popup after 0.5seconds
      setTimeout(function(){
        this.$parent.show_newsletter_popup = false;
      }.bind(this), 1000)
      // Register event
      this.$ga.event({
        eventAction:'3D_submit_newsletter', 
        eventCategory:'building_model',
      })
      // fbq('trackCustom', '3D_submit_newsletter');
    },
    close_modal(){
      this.$parent.show_newsletter_popup = false;
    }
  },
  computed:{
    email_correct: function(){
      var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(String(this.email).toLowerCase());      
    },
    disable_submit_button: function(){
      var condition_1 = this.email_correct
      console.log('Calcuationg>')
      return !(condition_1)
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
@import '../../../public/config.scss';
@import url('https://fonts.googleapis.com/css?family=Josefin+Sans:700|Raleway:300,500,700');
@import url("https://fonts.googleapis.com/css?family=Mali");

.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, .5);
  display: table;
  transition: opacity .3s ease;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}

.modal-container {
  max-width:90%;
  margin: 0px auto;
  padding: 20px 30px;
  background-color: #fff;
  border-radius: 2px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, .33);
  transition: all .3s ease;
  font-family: Helvetica, Arial, sans-serif;
  position: relative;
}
.modal-header{
  margin-top:1em;
}
.modal-header h3 {
  margin-top: 0;
  color: #42b983;
}
.modal-body {
  margin: 20px 0;
}

.modal-default-button {
  float: right;
}

/*
 * The following styles are auto-applied to elements with
 * transition="modal" when their visibility is toggled
 * by Vue.js.
 *
 * You can easily play with the modal transition by editing
 * these styles.
 */

.modal-enter {
  opacity: 0;
}

.modal-leave-active {
  opacity: 0;
}

.modal-enter .modal-container,
.modal-leave-active .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}

.title_nieuwsbrief{
  color: $reddish-orange;
  font-size:1.2em;
  font-weight: bold;
  margin-bottom:0.5em;
}
.modal-info p{
  margin-bottom:1em;
}
.close_button{
  position: absolute;
  right: 1em;
  top:1em;
  display: inline-block;
  display:inline-block;
  font-size:1.2em;
  color:grey;
}  
.input:focus, input:active{
  border-color: #4a90e2;
  -webkit-box-shadow: 0 0 0 0.125em rgba(249, 97, 40, 0.25);
  box-shadow: 0 0 0 0.125em rgba(74, 144, 226, 0.25);
}
.submit_button{
  max-width:200px;
  margin:auto;
  margin-top: 2em;
  font-size: 1.1em;
  font-weight:bolder;
  padding: 0.5em 0.75em;
  color: white;
  background: $dark-sky-blue;
  text-align: center;
  cursor: pointer;
  box-shadow: 0 3px 6px 0 $dark-sky-blue;  
  &:hover {
          background: darken($dark-sky-blue, 20%);
      }
  .fa{
    font-size: 1.7em;
    transform: translateX(5%) translateY(20%);
    -webkit-transform: translateX(5%) translateY(20%);
  }
}
.submit_button.disabled{
  opacity: 0.3;
}
.green_icon{
  color:seagreen !important;
}
.submitted{
  margin-top: 1em;
  font-weight: bold;
  color: #4a90e2;
}
.label_bold{
  font-weight:bolder;
}
</style>
