<template>
  <div id="app">
    <div id="spinning_wheel_whole_page" v-if="spinner_active">
      <div>
        <font-awesome-icon icon="spinner" class='spinner' />
      </div>
    </div>
    <keep-alive><navbar ref="navbar" v-if="navbar.show & $i18n.locale!='de'"></navbar></keep-alive>

    <div id="navbar-german" v-if="navbar.show & $i18n.locale=='de'">
      <img src="./assets/mibaby-logo.svg" alt="Image 1" class="other_logos">
      <img src="./assets/namesilike-logo.svg" alt="Image 2" id="logo_namesilike">
      <img src="./assets/logo_gutmarkiert.svg" alt="Image 3" class="other_logos">
    </div>

    <keep-alive><router-view/></keep-alive>
    <!-- <img v-if="render_facebook_pixel" height="1" width="1" src="https://www.facebook.com/tr?id=747412312303426&ev=PageView&noscript=1"/> -->
  </div>
</template>

<script>
import axios from 'axios'
import {event_bus} from './main' 
import Navbar from './components/Navbar.vue'

export default {
  metaInfo() {
    return{
      link:[
        {rel: 'shortcut icon', type:"image/png", href: 'favicon.png' }
      ]
    }
  },
  components:{
    'navbar': Navbar
  },
  data(){
    return {
      base_url:'',
      spinner_active: true,
      navbar:{
        show:true,
      },
      session_parameters:{
        user_id:'',
        session_id:'',
        model_id:'',
        ip_addres:'',
      }, 
      mounted:0,
      render_facebook_pixel:false,
    }
  },
  watch:{
  },
  methods: { 
    find_cookie(cname){
        var name = cname + "=";
        var ca = document.cookie.split(';');
        for(var i = 0; i < ca.length; i++) {
            var c = ca[i];
            while (c.charAt(0) == ' ') {
                c = c.substring(1);
            }
            if (c.indexOf(name) == 0) {
                var cookie_value = c.substring(name.length, c.length);
                return cookie_value
            }
        }
        return ''
    },
    set_cookie(cname, cvalue, exdays) {
        var d = new Date();
        d.setTime(d.getTime() + (exdays*24*60*60*1000));
        var expires = "expires="+d.toUTCString();
        document.cookie = cname + "=" + cvalue + "; " + expires;
    },
    make_id(){
      var text = "";
      var possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
      for( var i=0; i < 20; i++ )
          text += possible.charAt(Math.floor(Math.random() * possible.length));
      return text;
    },
    register_click(action, extra_json){
      axios
        .post(process.env.VUE_APP_API_URL + 'register_click', {
            'user_id': this.session_parameters.user_id,
            'session_id': this.session_parameters.session_id,            
            'model_id': this.session_parameters.model_id,            
            'locale': this.$i18n.locale,
            'action':action,
            'extra_json': extra_json,
            'app':'namesilike',
          })
        .then(function(response){
        })
        .catch(function(error){
        })
    }
  },
  created(){
    //console.log(process.env.VUE_APP_API_URL)
  },
  mounted(){ 
    this.spinner_active = true;  
    // Register event listener if locale changed
    event_bus.$on('language_set', function(locale){
      this.$i18n.locale = locale
      // Write coockie away
      this.set_cookie('locale', locale, 60)
    }.bind(this))
    // not if he comes from the email just checking his previous model results end here
    if(this.$route.name == 'view-recommendations'){
      this.session_parameters.model_id = this.$route.params.model_id
    }
    // First check if there is a previous user_id
    if(this.find_cookie("user_id")==''){
      // No user id, generate one, and store in coockies
      if(window.location.href.indexOf('localhost') != -1){
        this.session_parameters.user_id = 'development'
        //this.session_parameters.user_id = this.make_id()
      }else{
        this.session_parameters.user_id = this.make_id()
      }
      this.set_cookie('user_id', this.session_parameters.user_id, 60)
    }else{
      // Retake previous user_id and prefered locale
      this.session_parameters.user_id = this.find_cookie('user_id')
    }
    // Set user id with google analytics
    //this.$ga.set({ 'userId': this.session_parameters.user_id })
    // Create session id
    this.session_parameters.session_id = this.make_id()
    this.set_cookie('session_id', this.session_parameters.session_id, 60)
    // Register session, and get locale
    axios
      .get(process.env.VUE_APP_API_URL + 'register_session', {
        params: {
        'user_id': this.session_parameters.user_id,
        'session_id': this.session_parameters.session_id,
        'referrer': document.referrer,
        'current_url': window.location.href
      }})
      .then(function(response){
        // Check if there was not a cookie set with the locale
        if(this.find_cookie("locale")==''){
          // Use the locale from the geocoding
          this.$i18n.locale = response['data']['locale']
          event_bus.$emit('language_set', this.$i18n.locale)
        }else{
          // Use the locale from the cookie
          this.$i18n.locale = this.find_cookie('locale')
          event_bus.$emit('language_set', this.$i18n.locale)
        }
        // Take spinner of page
        this.spinner_active = false;
      }.bind(this))
    // Check if the user comes from google adwords. If so, register this with a click
    if(window.location.href.indexOf('?ad=1') != -1){
      this.register_click('google_adwords_1', '');
    }
    // Register with inspectlet. For now, only do so if the user comes from google adwords
    if( (window.location.href.indexOf('localhost') == -1) && 
          (window.location.href.indexOf('http://127.0.0.1') == -1) && 
          (window.location.href.indexOf('?ad=fr') != -1)){
    //if(window.location.href.indexOf('?ad=1') != -1){
      window.__insp = window.__insp || [];
        __insp.push(['wid', 263738354]);
        (function() {
        function ldinsp(){if(typeof window.__inspld != "undefined") return; window.__inspld = 1; var insp = document.createElement('script'); insp.type = 'text/javascript'; insp.async = true; insp.id = "inspsync"; insp.src = ('https:' == document.location.protocol ? 'https' : 'http') + '://cdn.inspectlet.com/inspectlet.js'; var x = document.getElementsByTagName('script')[0]; x.parentNode.insertBefore(insp, x); };
        setTimeout(ldinsp, 500); document.readyState != "complete" ? (window.attachEvent ? window.attachEvent('onload', ldinsp) : window.addEventListener('load', ldinsp, false)) : ldinsp();
        })();   
      __insp.push(['identify', this.session_parameters.user_id]);        
    } 
  }
}
</script>

<style lang="scss">
@import '../public/config.scss';
@import url('https://fonts.googleapis.com/css?family=Josefin+Sans:700|Raleway:300,500,700');

html {
  background: $bg-color;
  background-image: url('/pattern.svg'), radial-gradient(circle at 50% 50%, $bg-color, $bg-color);
  background-size: 100% auto;
  background-position: center top;
  background-repeat: repeat-y;

  //font-weight: $text-weight-normal;
  //line-height: $text-line-height;
  
  //@media #{$up-to-tablet} {
    //font-size: (14 / 18) * 62.5%;
  //}
  //@media #{$laptop-and-up} {
  //    line-height: $text-line-height-laptop-and-up;
  //    font-size: ($text-size-laptop-and-up / $text-size) * 62.5%;
  //}
}
body{
  font-family: $text-family;
  color: $text-color;
  font-weight: 300;
  // line-height: 1.5;
  // font-size:1.25em;
}
b{
  font-weight: bolder;
}

#nav{
  margin: 1em;
}
#navbar-german {
  display: flex;
  justify-content: center; /* Centers images horizontally */
  align-items: center; /* Centers images vertically */
  margin-top:3em;
}

#navbar-german #logo_namesilike {
  max-height: 4em; /* Adjust the maximum height as needed */
  margin: 0 10px; /* Adds a small margin between images */
}
#navbar-german .other_logos {
  max-height: 2em; /* Adjust the maximum height as needed */
  margin: 0 10px; /* Adds a small margin between images */
}
.button{
  font-weight: 700;
}

#spinning_wheel_whole_page {
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-image: radial-gradient(circle at 50% 50%, rgba(0,0,0,0.5), rgba(0,0,0,0.8));
    z-index: 999;
}
#spinning_wheel_whole_page div {
    font-size: 4em;
    color:$info;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    -webkit-transform: translateX(-50%) translateY(-50%);
} 
@keyframes fa-spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}
.spinner{
  -webkit-animation: fa-spin 2s infinite linear;
  animation: fa-spin 2s infinite linear;
}
#logo_goedgemerkt{
  position:absolute;
  bottom:0.5em;
  height:4em;
  width:7em;
  right:1em;
  background-image: url('https://www.goedgemerkt.nl/images/nl/logo.svg');
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain; 

}


</style>
