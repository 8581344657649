<template>
	<div>
    <section class="section has-text-centered">
      <h1 class="title">Statistieken van honderden voornamen</h1>
      <p class="subtitle">Ben je benieuwd hoe populair je favoriete voornaam is? Of deze aan het trenden is?
      </p>
    </section>
    <section class="section has-text-centered">
        <div class="columns is-centered">
          <div class="column is-half">
            <b-field label="Selecteer een voornaam">
              <b-autocomplete
                  rounded
                  v-model="name"
                  :data="filteredDataArray"
                  placeholder="e.g. jQuery"
                  icon="search"
                  @select="handle_selection">
                  <template slot="empty">No results found</template>
              </b-autocomplete>
            </b-field>
          </div>
        </div>

    </section>
    <section class="section has-text-centered" v-if="initialize_chart">
      <div class="columns is-centered">
        <div class="column is-half">
          <stats-line-chart :chart-data="chart_data" :options="chart_options"></stats-line-chart>
        </div>
      </div>
    </section>
	</div>
</template>

<script>

import stats from './../assets/stats_tool_data/stats.json'
import StatsLineChart from '@/components/StatsLineChart.js'

export default {
  metaInfo() {
    return{
      // if no subcomponents specify a metaInfo.title, this title will be used
      // title: this.$t('app_component.title'),
      title:'Statistieken van honderden voornamen | Names I Like',
      // all titles will be injected into this template
      titleTemplate: '%s', 
      meta: [
        { charset: 'utf-8' },
        { name: "viewport", content: "width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no" },
        { name: 'google', content: 'notranslate' },
        { name: 'google-site-verification', content: 'onWYxFnSJqLMzGgtpht5CWBMGoRJQmx9dbt1xjwFGL8' },
        // { name: 'lang', content: this.$t('app_component.language_meta') },
        { name: 'lang', content: 'nl' },
        { name: 'description', content: 'Met ons algoritme was naar babynamen zoeken nog nooit zo leuk! We verzamelen alle babynaam stastieken en geven persoonlijke suggesties!' },                             
        // { name: 'title', content: this.$t('app_component.title') },
        { name: 'title', content: 'Statistieken van honderden voornamen | Names I Like' },
        // { property: 'og:title', content: this.$t('app_component.title') },   
        { property: 'og:title', content: 'Statistieken van honderden voornamen | Names I Like' },  
        // { property: 'og:description', content: this.$t('app_component.description') },   
        { property: 'og:description', content: 'Met ons algoritme was naar babynamen zoeken nog nooit zo leuk! We verzamelen alle babynaam stastieken en geven persoonlijke suggesties!' },   
        { property: 'og:url', content: 'https://www.namesilike.com' },   
        { property: 'og:locale', content: 'nl' },   
        ],
    }
  },   
	components: {
    StatsLineChart
  },
  created(){
    console.log('Created')
    // Find all the unique names
    this.unique_names = stats.map(x => x['Name'])
    this.unique_names = this.unique_names.filter((v,i) => this.unique_names.indexOf(v) === i)
    this.unique_names.sort()

  },    
  data(){
    return{
      name: '',
      selected_name: '',
      unique_names:[],
      chart_data:{},
      chart_options:{'legend':{
                        'position':'bottom'
                      },
                      'title':{'text':'', // Title is set dynamically when the name is selected
                                  'display':false,
                        'fontFamily': "Raleway",
                        'fontSize':16,
                        'fontColor':'#211551'
                      },
                      'scales':{
                        'yAxes':[{
                          'ticks':{
                            'beginAtZero':true,
                          },
                          'scaleLabel':{
                            'display': true,
                            'labelString': '# per 10.000'
                          }
                        }]
                      },
      },
      initialize_chart:false,
    }
  },
  computed: {
      filteredDataArray() {
          return this.unique_names.filter((option) => {
              return option
                  .toString()
                  .toLowerCase()
                  .indexOf(this.name.toLowerCase()) >= 0
          })
      }
  },
  methods:{
    handle_selection(selected_name){
      this.chart_data = {
        'labels': [1995, 1996, 1997, 1998, 1999, 2000, 2001,  2002, 2003, 2004, 2005,
                            2006, 2007, 2008, 2009, 2010, 2011, 2012, 2013, 2014, 2015, 
                            2016, 2017, 2018],
        'datasets':[]
      }
      this.selected_name = selected_name
      this.chart_options.title.text = 'Populariteit van de voornaam ' + this.selected_name
      // Filter the entries in the stats json
      let stats_selected_name = stats.filter(stats_name => stats_name['Name']==selected_name)
      // Make for each timeseries a data object
      let colors = ['#66c2a5', '#fc8d62', '#8da0cb', '#e78ac3', '#a6d854', '#ffd92f']
      stats_selected_name.forEach((stats_name, i) => {
        let dataset = {
          'label':stats_name['Region'],
          'data': JSON.parse(stats_name['ts_json']),
          'fill':false,
          'borderColor': colors[i],
          'pointRadius':0,
        }
        this.chart_data.datasets.push(dataset)
      })
      // Initialise chart
      this.initialize_chart = true
    }
  }


}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style  scoped>
</style>