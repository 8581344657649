import Vue from 'vue'


import 'buefy/dist/buefy.css' //In Rockestate app import 'buefy/dist/buefy.css'

import App from './App.vue'
import router from './router'

import Meta from 'vue-meta'
Vue.use(Meta)

import * as Sentry from '@sentry/browser';
import { Vue as VueIntegration } from '@sentry/integrations';

if(window.location.href.indexOf('localhost') == -1){
  Sentry.init({
    dsn: 'https://0a64c99e8f8546fa8f5ed8f383ac0499@o412825.ingest.sentry.io/5341912',
    integrations: [new VueIntegration({Vue, attachProps: true})],
  });
}


var VueScrollTo = require('vue-scrollto');
Vue.use(VueScrollTo, {
  container: "body",
  duration: 500,
  easing: "ease",
  offset: -50,
  force: true,
  cancelable: true,
  onStart: false,
  onDone: false,
  onCancel: false,
  x: false,
  y: true
})



// router.beforeEach((to, from, next) => {
//   console.log(to)
// })
import VueI18n from 'vue-i18n'
Vue.use(VueI18n)

export const event_bus = new Vue();

// Fpont awesome
import { library } from '@fortawesome/fontawesome-svg-core'
import { faHome } from '@fortawesome/free-solid-svg-icons'
import { faTrash } from '@fortawesome/free-solid-svg-icons'
import { faTrashAlt } from '@fortawesome/free-solid-svg-icons'
import { faChartLine } from '@fortawesome/free-solid-svg-icons'
import { faInfo } from '@fortawesome/free-solid-svg-icons'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import { faCaretDown } from '@fortawesome/free-solid-svg-icons'
import { faCaretUp } from '@fortawesome/free-solid-svg-icons'
import { faCaretRight } from '@fortawesome/free-solid-svg-icons'
import { faTrophy } from '@fortawesome/free-solid-svg-icons'
import { faGift } from '@fortawesome/free-solid-svg-icons'
import { faMars } from '@fortawesome/free-solid-svg-icons'
import { faVenus } from '@fortawesome/free-solid-svg-icons'
import { faTimes } from '@fortawesome/free-solid-svg-icons'
import { faCheck } from '@fortawesome/free-solid-svg-icons'
import { faEnvelope } from '@fortawesome/free-solid-svg-icons'
import { faPaperPlane } from '@fortawesome/free-solid-svg-icons'
import { faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons'
import { faWindowMaximize } from '@fortawesome/free-solid-svg-icons'
import { faSearch } from '@fortawesome/free-solid-svg-icons'

import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

library.add(faSpinner)
library.add(faHome)
library.add(faTrash)
library.add(faTrashAlt)
library.add(faChartLine)
library.add(faInfo)
library.add(faCaretDown)
library.add(faCaretUp)
library.add(faCaretRight)
library.add(faTrophy)
library.add(faTimes)
library.add(faGift)
library.add(faMars)
library.add(faVenus)
library.add(faCheck)
library.add(faPaperPlane)
library.add(faEnvelope)
library.add(faExternalLinkAlt)
library.add(faWindowMaximize)
library.add(faSearch)

Vue.component('font-awesome-icon', FontAwesomeIcon)

import Buefy from 'buefy'
Vue.use(Buefy, {
  defaultIconComponent: 'font-awesome-icon',
  defaultIconPack: 'fas',
})

// Production mode? If running with Vue Ui this NODE_ENV is development, 
// but if the app is build and served with Flask, it is still in development mode
Vue.config.productionTip = true
const debug = (process.env.NODE_ENV=='development') || (window.location.href.indexOf('http://127.0.0.1:5000') > -1)
if(debug) console.log('Debug parameter set to ' + debug)

// Vue analystics
import VueAnalytics from 'vue-analytics'
Vue.use(VueAnalytics, {
  id: 'UA-103141746-1',
  debug:{
    enabled:false,
    sendHitTask:!debug,
  }
})

import RecommenderSystem from './views/RecommenderSystem.vue'
import About from './views/About.vue'
import InspirationTool from './views/InspirationTool.vue'
import StatsTool from './views/StatsTool.vue'

Vue.component('recommender-system', RecommenderSystem)
Vue.component('about', About)
Vue.component('inspiration-tool', InspirationTool)
Vue.component('stats-tool', StatsTool)

// Create VueI18n instance with options
import messages from './text_different_languages.js'

const i18n = new VueI18n({
  locale: 'be_nl', // set locale
  messages, // set locale messages
})

new Vue({
  router,
  i18n,
  render: h => h(App)
}).$mount('#app')
