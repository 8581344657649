import { render, staticRenderFns } from "./StatsTool.vue?vue&type=template&id=517da81f&scoped=true&"
import script from "./StatsTool.vue?vue&type=script&lang=js&"
export * from "./StatsTool.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "517da81f",
  null
  
)

export default component.exports