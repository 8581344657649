<template>
  <div style="height: 116px;">
    <div class="navbar_wrapper">
      <b-navbar>
        <template slot="brand">
          <b-navbar-item tag="router-link" :to="{ path: '/' }">
            <img
              v-if="modal_menu_open"
              src="../assets/names-I-like-logo-inverted.svg"
              class="navbar-logo"
              alt=""
            />
            <div v-else>
              <img
              src="../assets/namesilike-logo.svg"
              class="navbar-logo"
              alt=""
            />
            </div>
          </b-navbar-item>
          <b-dropdown :mobile-modal="false" class="mobile-dropdown" v-if="locale!='de'">
            <button class="mobile-language-button language-button" type="button" slot="trigger">
              <img
                v-bind:src="selected_language_menu_image_url"
                class="language-img current-language"
                alt="language icon"
              />
              <span>{{selected_language_menu_text}}</span>
              <div class="arrow_down"></div>
            </button>

            <b-dropdown-item class="language-list-item" value="option1" aria-role="listitem" @click="handle_language_selection('be_nl')">
              <img
                class="language-img"
                src="../assets/country_flags/belgium.svg"
                alt="language icon"
              />
              <span>BE (NL)</span>
            </b-dropdown-item>

            <b-dropdown-item class="language-list-item" value="option2" aria-role="listitem" @click="handle_language_selection('be_fr')">
              <img
                class="language-img"
                src="../assets/country_flags/belgium.svg"
                alt="language icon"
              />
              <span>BE (FR)</span>
            </b-dropdown-item>

            <b-dropdown-item class="language-list-item" value="option2" aria-role="listitem" @click="handle_language_selection('nl')">
              <img
                class="language-img"
                src="../assets/country_flags/netherlands.svg"
                alt="language icon"
              />
              <span>NL</span>
            </b-dropdown-item>

            <b-dropdown-item class="language-list-item" value="option2" aria-role="listitem" @click="handle_language_selection('fr')">
              <img
                class="language-img"
                src="../assets/country_flags/france.svg"
                alt="language icon"
              />
              <span>FR</span>
            </b-dropdown-item>            

            <b-dropdown-item class="language-list-item" value="option2" aria-role="listitem" @click="handle_language_selection('de')">
                <img
                  class="language-img"
                  src="../assets/country_flags/germany.svg"
                  alt="language icon"
                />
                <span>DE</span>
            </b-dropdown-item>            
          </b-dropdown>

        </template>
        <template slot="start" v-if="locale!='de'">
          <b-navbar-item tag="router-link" to="/" :class="{current:highlighted_navbar_item=='homepage'}"
                    v-on:click.native="handle_home_button"
                    v-if="this.locale!='de'">Home</b-navbar-item>
          <b-navbar-item tag="router-link" to="/meisjesnamen" 
                    :class="{current:highlighted_navbar_item=='meisjesnamen'}"
                    v-on:click.native="handle_meisjesnamen_button"
                    v-if="this.locale!='de'">Meisjesnamen</b-navbar-item>
          <b-navbar-item tag="router-link" to="/jongensnamen" 
                    :class="{current:highlighted_navbar_item=='jongensnamen'}"
                    v-on:click.native="handle_jongensnamen_button"
                    v-if="this.locale!='de'">Jongensnamen</b-navbar-item>
          <b-navbar-item href="https://www.namesilike.com/babynamen-blog/index.html"
                    v-on:click.native="handle_blog_button"
                    v-if="this.locale!='de'">Blog</b-navbar-item>
          
          <!-- <b-navbar-item tag="router-link" to="/about" :class="{current:highlighted_navbar_item=='about'}">About</b-navbar-item> -->
        </template>

        <template slot="end">
          <b-navbar-item href="#">
            <b-dropdown :mobile-modal="false">
              <button class="language-button" type="button" slot="trigger">
                <img v-bind:src="selected_language_menu_image_url"
                  class="language-img current-language"
                  alt="language icon"
                />
                <span>{{selected_language_menu_text}}</span>
                <div class="arrow_down"></div>
              </button>

              <b-dropdown-item class="language-list-item" value="option1" aria-role="listitem" @click="handle_language_selection('be_nl')">
                <img
                  class="language-img"
                  src="../assets/country_flags/belgium.svg"
                  alt="language icon"
                />
                <span>BE (NL)</span>
              </b-dropdown-item>

              <b-dropdown-item class="language-list-item" value="option2" aria-role="listitem" @click="handle_language_selection('be_fr')">
                <img
                  class="language-img"
                  src="../assets/country_flags/belgium.svg"
                  alt="language icon"
                />
                <span>BE (FR)</span>
              </b-dropdown-item>

              <b-dropdown-item class="language-list-item" value="option3" aria-role="listitem" @click="handle_language_selection('nl')">
                <img
                  class="language-img"
                  src="../assets/country_flags/netherlands.svg"
                  alt="language icon"
                />
                <span>NL</span>
              </b-dropdown-item>

              <b-dropdown-item class="language-list-item" value="option3" aria-role="listitem" @click="handle_language_selection('fr')">
                <img
                  class="language-img"
                  src="../assets/country_flags/netherlands.svg"
                  alt="language icon"
                />
                <span>FR</span>
              </b-dropdown-item>

              <b-dropdown-item class="language-list-item" value="option3" aria-role="listitem" @click="handle_language_selection('de')">
                <img
                  class="language-img"
                  src="../assets/country_flags/germany.svg"
                  alt="language icon"
                />
                <span>DE</span>
              </b-dropdown-item>              

            </b-dropdown>
          </b-navbar-item>
        </template>
      </b-navbar>
    </div>
  </div>
</template>

<script>
import { event_bus } from "../main";

export default {
  data() {
    return {
      language_buttons: [
        {
          text: "BE (nl)",
          url: "/country_flags/belgium.svg",
          selected: false,
          show: true,
          id: "be_nl"
        },
        {
          text: "BE (fr)",
          url: "/country_flags/belgium.svg",
          selected: false,
          show: false,
          id: "be_fr"
        },
        {
          text: "NL",
          url: "/country_flags/netherlands.svg",
          selected: false,
          show: false,
          id: "nl"
        },
        {
          text: "FR",
          url: "/country_flags/france.svg",
          selected: false,
          show: false,
          id: "fr"
        } ,
        {
          text: "DE",
          url: "/country_flags/germany.svg",
          selected: false,
          show: false,
          id: "de"
        }        
      ],
      languague_menu_open: false,
      locale: "",
      show_language_buttons: true,
      show_home_button: false,
      show_blog_button: true,
      modal_menu_open: false,
      highlighted_navbar_item:'',
      // New language menu stuff
      selected_language_menu_text:'',
      selected_language_menu_image_url:''
    };
  },
  computed: {
    active_language_buttons: function() {
      return this.language_buttons.filter(function(button) {
        return button["show"];
      });
    }
  },
  watch: {
    $route(to, from) {
      // If the route is changed, change the highlighed menu item
      this.highlighted_navbar_item = to.name;
      // The show / hide of the buttons is controlled via the routes. Exceptions are handled via fired events
      // In the result page of the recommendations an event is fired to force to show the home
      // button
      // this.show_language_buttons = false;
      // this.show_home_button = false;
      // this.show_blog_button = false;
      // if (to.name == "homepage") {
      //   this.show_language_buttons = true;
      //   this.show_home_button = false;
      //   this.show_blog_button = true;
      // }
    }
  },
  mounted() {
    // Check which menu item to highlight
    this.highlighted_navbar_item = this.$route.name
    // Buefy Navbar stuff
    document.onclick = e => {
      if(!this.$parent.navbar.show) return null
      let menu = document.querySelector(".navbar-menu");
      if (menu.classList.contains("is-active")) this.modal_menu_open = true;
      else this.modal_menu_open = false;
    };
    let navWrapper = document.querySelector(".navbar_wrapper");
    document.body.onscroll = e => {
      if (pageYOffset > 50) {
        navWrapper.style.backgroundColor = "rgb(255, 216, 81)";
        navWrapper.style.boxShadow = "0 0 4px #00000087";
      }
      if (pageYOffset < 50) {
        navWrapper.style.backgroundColor = "transparent";
        navWrapper.style.boxShadow = "";
      }
    };
    
  },
  created() {
    // Language stuff
    this.locale = this.$i18n.locale;
    event_bus.$on(
      "language_set",
      function(locale) {
        this.$i18n.locale = locale;
        this.locale = locale;
        // Select correct language coming from higher up
        if(locale=='be_nl'){
          this.selected_language_menu_text="BE (NL)"
          this.selected_language_menu_image_url='/country_flags/belgium.svg'
        }else if(locale=='be_fr'){
          this.selected_language_menu_text="BE (FR)"
          this.selected_language_menu_image_url='/country_flags/belgium.svg'
        }else if(locale=='nl'){
          this.selected_language_menu_text="NL"
          this.selected_language_menu_image_url='/country_flags/netherlands.svg'
        }else if(locale=='fr'){
          this.selected_language_menu_text="FR"
          this.selected_language_menu_image_url='/country_flags/france.svg'
        }else if(locale=='de'){
          this.selected_language_menu_text="DE"
          this.selected_language_menu_image_url='/country_flags/germany.svg'
        }        
      }.bind(this)
    );
    event_bus.$on(
      "force_home_button_to_show",
      function() {
        this.show_home_button = true;
      }.bind(this)
    );
    event_bus.$on(
      "force_language_buttons_to_hide",
      function() {
        this.show_language_buttons = false;
      }.bind(this)
    );
    // if Locale is de, hide hamburger menu
    // console.log('hier')
    // console.log(this.locale)
    // if(this.locale=='de'){
    //   document.getElementsByClassName("navbar-burger burger").classList.add('hidden');
    // }
  },
  methods: {
    set_cookie(cname, cvalue, exdays) {
      var d = new Date();
      d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
      var expires = "expires=" + d.toUTCString();
      document.cookie = cname + "=" + cvalue + "; " + expires;
    },
    update_language_icon() {},
    handle_home_button() {
      // This triggers some stuff in the APP.vue, but resets also everything in the RecommenderSystem.vue
      event_bus.$emit("home_button_clicked");
      this.$parent.register_click("navbar_home", "");
      this.$parent.navbar.show_language_buttons = true;
    },
    handle_meisjesnamen_button(){
      this.$parent.register_click("navbar_meisjesnamen", "");
    },
    handle_jongensnamen_button(){
      this.$parent.register_click("navbar_jongensnamen", "");
    },    
    handle_blog_button() {
      // This triggers some stuff in the APP.vue, but resets also everything in the RecommenderSystem.vue
      //event_bus.$emit('home_button_clicked')
      this.$ga.event({
        eventAction: "5_blog_nav_clicked",
        eventCategory: "blog"
      });
      this.$parent.register_click("navbar_blog", "");
      //this.$parent.navbar.show_language_buttons = true;
      window.location.href = "/babynamen-blog/blog-index.html";
    },
    handle_language_selection(clicked_locale) {
      // Show new selected button
      if(clicked_locale=='be_nl'){
        this.selected_language_menu_text="BE (NL)"
        this.selected_language_menu_image_url='/country_flags/belgium.svg'
      }else if(clicked_locale=='be_fr'){
        this.selected_language_menu_text="BE (FR)"
        this.selected_language_menu_image_url='/country_flags/belgium.svg'
      }else if(clicked_locale=='nl'){
        this.selected_language_menu_text="NL"
        this.selected_language_menu_image_url='/country_flags/netherlands.svg'
      }else if(clicked_locale=='fr'){
        this.selected_language_menu_text="FR"
        this.selected_language_menu_image_url='/country_flags/france.svg'
      }      
      // GA
      this.$parent.register_click("languague_button_to_" + clicked_locale, "");
      // Event bus
      event_bus.$emit("language_set", clicked_locale);
      // Set coockie
      this.set_cookie("locale", clicked_locale, 60);
      // Hide hamburger menu if it is german
      console.log('hier3')
      if(clicked_locale=='de'){
        console.log('hier2')
        console.log(document.getElementsByClassName("navbar-burger burger")[0].classList)
        document.getElementsByClassName("navbar-burger burger")[0].classList.add('hidden');
      }


      // OLD LOGIC
      // If language menu is not open, expand
      // if (!this.languague_menu_open) {
      //   this.languague_menu_open = true;
      //   for (var i = 0; i < this.language_buttons.length; i++) {
      //     this.language_buttons[i].show = true;
      //     this.language_buttons[i].selected = false;
      //   }
      //   // If language menu was open, assume the user clicked on a flag.
      // } else {
      //   this.$parent.register_click("languague_button_to_" + id_clicked, "");
      //   event_bus.$emit("language_set", id_clicked);
      //   this.set_cookie("locale", id_clicked, 60);
      //   for (var i = 0; i < this.language_buttons.length; i++) {
      //     if (this.language_buttons[i].id == id_clicked) {
      //       this.language_buttons[i].show = true;
      //       this.language_buttons[i].selected = true;
      //     } else {
      //       this.language_buttons[i].show = false;
      //       this.language_buttons[i].selected = false;
      //     }
      //     this.languague_menu_open = false;
      //   }
      // }
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->

<style lang="scss" scoped>
@import '../../public/config.scss';
@import url('https://fonts.googleapis.com/css?family=Josefin+Sans:700|Raleway:300,500,700');

button {
  &:focus {
    outline: none;
  }
}

.navbar_wrapper { // most general styles for all navbar
  width: 100%;
  position: fixed;
  z-index: 10;
  transition: 1s;
  transition-property: background-color, box-shadow;
  .navbar {
    background-color: $sun-yellow;;
    max-width: 1250px;
    margin: 0 auto;
    padding-top: 20px;
    // padding-bottom: 20px; Padding bottom is the ::after element with transparent gradient
    .current {
      font-weight: bold;
      &::after { // pseudo element to display bottom border under selected navbar item
        content: "";
        position: absolute;
        width: 100%;
        height: 4px;
        left: 0;
        bottom: 5px;
        background-color: rgb(255, 97, 57);
        border-radius: 5px;
      }
    }
    .navbar-logo {
      height: 60px;
      max-height: 60px;
      margin-left:2em;
    }
    .language-button { // button to display dropdown with languages
      background-color: rgba(0, 0, 0, 0.3);
      border: none;
      border-radius: 10px;
      display: flex;
      align-items: center;
      padding: 5px 10px;
      min-width: 130px;
      color: white;
      cursor: pointer;
      line-height: 30px;
      font-size: 15px;
      justify-content: flex-start;
      .current-language {
        margin-right: 5px;
        span {
          white-space: nowrap;
        }
      }
    }
    .language-list-item { // item of dropdown with languages
      display: flex;
      align-items: center;
      padding: 5px 10px;
    }
    .language-img {
      margin-right: 5px;
      max-height: 30px;
    }
    .arrow_down {
      width: 0;
      height: 0;
      margin-left: auto;
      border-style: solid;
      border-width: 5px 5px 0 5px;
      border-color: #ffffff transparent transparent transparent; // this and 2 upper lines create arrow for dropdown
    }
    .mobile-language-button {
      display: none;
    }
  }
}
.navbar_wrapper::after{
  content:" ";
  display: block;
  // CSS safari issue, transparent creates this black transparent thing on safari. 
  // So let it gradiate to the original color but transparent. rgb(255,216,59) = sun-yellow
  background-image:linear-gradient(
      to bottom, 
      $sun-yellow, 
      rgba(255,216,59, 0.01) 
    );
  height:20px;
  width:100%;
}

.navbar-menu {
  .navbar-item { // styles for navbar items (page title and dropdown)
    background-color: transparent;
    font-weight: 400;
    padding: 0;
    margin: 0.5rem 0.75rem;
    &:hover,
    &:focus {
      outline: none;
      background-color: transparent;
    }
  }
}
</style>

<style lang="scss"> // global styles are needed to overwrite some buefy styles
.navbar-item,
.dropdown {
  .dropdown-menu {
    width: 100%;
    min-width: 0px;
    border-radius: 10px;
  }
  .dropdown-content {
    background-color: transparent;
    padding: 0;
    border-radius: 10px;
  }
  .dropdown-item {
    background-color: rgba(0, 0, 0, 0.3);
    color: rgba(255, 255, 255, 1);
    &:first-child { // make rounded borders for first and last items of dropdown
      border-top-left-radius: 10px;
      border-top-right-radius: 10px;
    }
    &:last-child {
      border-bottom-left-radius: 10px;
      border-bottom-right-radius: 10px;
    }
    &.is-active {
      background-color: rgba(0, 0, 0, 0.3);
      color: rgba(255, 255, 255, 1);
    }
    &:hover {
      background-color: rgba(0, 0, 0, 0.3);
      color: white;
      font-weight: 600;
    }
    &:focus {
      outline: none;
    }
  }
}
.mobile-dropdown .dropdown-menu {
  margin-top: -15px; // reduce big offset from dropdown button to dropdown list
}
@media (max-width: 1023px) {
  .navbar-end {
    display: none !important; // disable displaying the dropdown list in modal window on mobile ver
  }
  .current::after {
    display: none; // disable bottom border on mobile ver
  }
  .navbar {
    .navbar-burger {
      height: auto;
      order: -1; // move mobile burger to first position in navbar
      margin: 0 0 0 15px;
      span {
        height: 3px;
        border-radius: 3px;
      }
      &:hover {
        background-color: transparent;
      }
      &.is-active span {
        background-color: white;
      }
    }
    .navbar-menu.is-active { // styles for mobile menu modal
      width: 100vw;
      height: 100vh;
      position: fixed; // fixed position to display modal over all elements and fixate it relative to the window
      left: 0;
      top: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: rgb(39, 26, 80);
      font-size: 2.5em;
      z-index: -1;
      .navbar-item {
        font-weight: bold;
        text-align: center;
        color: rgba(255, 255, 255, 0.4);
        &:hover {
          color: white;
        }
      }
    }
    .dropdown {
      margin-right: 35px;
      align-items: center;
      margin-left: auto; // move dropdown to the right
    }
    .mobile-language-button {
      display: flex!important;
      white-space: nowrap;
      min-width: 130px;
    }
  }
}
</style>